<template>
  <loading v-if="isLoading" />
  <content-not-view v-else-if="!studentsPermission.store" />
  <div class="page-box" v-else>
    <div class="intro">
      <div>
        <span class="title">{{ $t("g.courses_students/add_students") }}</span>
        <span class="edu-year">
          {{ `${$t("g.Grade")} ` }}
          <span class="spcial-text" v-if="edu_years_name">
            .{{ edu_years_name }}
          </span>
          <span class="spcial-text" v-else> .{{ $t("g.summer_course") }} </span>
        </span>
      </div>
      <b-button
        @click="addStudentCourse"
        variant="primary"
        :disabled="students_id.length == 0"
        class="add-students"
        >{{ $t("g.courses_students/add_students") }}</b-button
      >
    </div>
    <div class="answer-table">
      <div class="answer-box-main">
        <b-card>
          <b-card-header class="header d-flex justify-content-between">
            <div class="text-left">{{ $t("g.student") }}</div>
            <div>{{ $t("g.edu_year") }}</div>
            <div>{{ $t("g.joining_date") }}</div>
          </b-card-header>
        </b-card>
        <b-card
          v-for="(item, index) in students"
          :key="index"
          class="answer-item"
        >
          <b-card-header class="d-flex justify-content-between card-header">
            <div class="item-data d-flex align-items-center">
              <b-form>
                <b-form-checkbox
                  :value="item.id"
                  v-model="students_id"
                ></b-form-checkbox>
              </b-form>
              <b-img
                v-if="item.user"
                class="img"
                :src="
                  $helpers.filterImages(
                    $helpers.uiAvatar(item.user.first_name),
                    'path',
                    item.user.media
                  )
                "
              />{{
                item.user
                  ? item.user.first_name + " " + item.user.last_name
                  : ""
              }}
            </div>
            <div class="scores item-data text-center">
              <span>{{ item.eduyear.name }}</span>
            </div>
            <div class="item-data text-center">
              {{ formate(item.created_at) }}
            </div>
          </b-card-header>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BImg,
  BCardHeader,
  BLink,
  BFormCheckbox,
  BForm,
  BFormGroup,
} from "bootstrap-vue";
import formatDate from "@/composables/format-date/format-date";
import Loading from "@/components/loading/loading.vue";
import contentNotView from "@/components/contentNotView/contentNotView.vue";
import NoDataComponent from "@/components/shared/NoDataComponent/index.vue";
export default {
  components: {
    NoDataComponent,
    BButton,
    BCard,
    BImg,
    BCardHeader,
    BLink,
    BFormCheckbox,
    BForm,
    BFormGroup,
    Loading,
    contentNotView,
  },
  mounted() {
    if (this.$route.params.id) {
      this.course_id = this.$route.params.id;
      this.getCourse();
    }
  },
  computed: {
    studentsPermission() {
      this.$store.dispatch("GET_PERMISSION", "students");
      return this.$store.state.userData.sub_Permission;
    },
  },
  data() {
    return {
      formate: formatDate,
      students_id: [],
      students: [],
      edu_years_name: "",
      edu_year_id: null,
      isLoading: false,
    };
  },
  methods: {
    async getStudents() {
      this.isLoading = true;
      try {
        const res = await this.$http.get(`admin/edu-years/${this.edu_year_id}`);
        this.students = res.data.data.students;
        if (res.data.data.length === 0) {
          this.$helpers.makeToast(
            "warning",
            res.data.message,
            res.data.message
          );
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    getCourse() {
      this.$http
        .get(`/admin/courses/${this.course_id}`)
        .then((res) => {
          this.edu_years_name = res.data.data.edu_years[0]?.name;
          this.edu_year_id = res.data.data.edu_years[0]?.id;
          this.getStudents();
        })
        .catch((error) => {
          this.$helpers.handleError(error);
        });
    },
    addStudentCourse() {
      this.isLoading = true;

      let formData = {
        students: this.students_id,
        course_id: this.$route.params.id,
      };

      this.$http
        .post(`admin/enrollments`, formData)
        .then((success) => {
          if (success) {
            if (success.status === 200 || success.status === 201) {
              this.$helpers.makeToast(
                "success",
                success.data.message,
                success.data.message
              );
              this.getStudents();
              setTimeout(() => {
                this.students_id = [];
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.$helpers.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "./courseAddStudents.scss";
</style>
